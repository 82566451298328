import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { RepositoryService } from '../../services/repository/repository.service';
import validator from 'validator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountPage } from '../account/account.page';
import { Api } from '../../../api/api';
import { environment } from '../../../environments/environment';
import { Gender } from 'src/enums/Gender';
import { HomePage } from '../home/home.page';
import { MenuPage } from '../menu/menu.page';
import { TranslateService } from '@ngx-translate/core';
import { ValidationUtils } from '../../../utils/validation-utils';
import { Subscription } from 'rxjs';
import { SignInPage } from '../sign-in/sign-in.page';
import { AuthStrategy } from '../../../models/AuthStrategy';
import { EmailConfirmationPage } from '../email-confirmation/email-confirmation.page';
import Utils from '../../../utils';
import { ModalController } from '@ionic/angular';
import { TosPage } from '../tos/tos.page';

@Component({
	selector: 'app-sign-up',
	templateUrl: '../../../projects/aspasia/pages/sign-up/sign-up.page.html',
	styleUrls: ['sign-up.page.scss'],
})
export class SignUpPage implements OnDestroy {
	static url = 'sign-up';

	showRePassword = false;
	showPassword = false;
	isValidEmail = true;
	isValidName = true;
	isValidPassword = true;
	isValidRePassword = true;

	email = '';
	password = '';
	rePassword = '';
	fullName = '';
	agbChecked = false;
	loading = false;
	isValid = false;
	Gender = Gender;
	gender: Gender = null;

	private readonly subscription: Subscription;

	constructor(
		private translate: TranslateService,
		private router: Router,
		private repository: RepositoryService,
		private snackbarCtrl: MatSnackBar,
		private modalCtrl: ModalController
	) {
		if (repository.customer && !repository.customer.isAnonymous) {
			AccountPage.navigate(router);
		}
	}

	static navigate(router: Router) {
		router.navigateByUrl(SignUpPage.url);
	}

	ngOnDestroy() {
		if (this.subscription && !this.subscription.closed) {
			this.subscription.unsubscribe();
		}
		this.snackbarCtrl.dismiss();
	}

	validate() {
		if (!environment.isAspasiaBuild) {
			if (this.gender === null) {
				this.isValid = false;
				this.snackbarCtrl.open(this.translate.instant('sign_up.validation.gender'), null, {
					duration: 5000,
				});
				return;
			}
		}

		if (this.fullName.split(' ').length < 2) {
			this.isValid = false;
			this.isValidName = false;
			this.snackbarCtrl.open(this.translate.instant('sign_up.validation.name'), null, {
				duration: 5000,
			});
			return;
		} else {
			this.isValidName = true;
		}

		if (!validator.isEmail(this.email)) {
			this.isValid = false;
			this.isValidEmail = false;
			this.snackbarCtrl.open(this.translate.instant('sign_up.validation.email'), null, {
				duration: 5000,
			});
			return;
		} else {
			this.isValidEmail = true;
		}

		if (!ValidationUtils.validatePassword(this.password)) {
			this.isValid = false;
			this.isValidPassword = false;
			this.snackbarCtrl.open(this.translate.instant('sign_up.validation.password'), null, {
				duration: 6000,
			});
			return;
		} else {
			this.isValidPassword = true;
		}
		if (this.password !== this.rePassword) {
			this.isValid = false;
			this.isValidRePassword = false;
			this.snackbarCtrl.open(this.translate.instant('sign_up.validation.re_password'), null, {
				duration: 5000,
			});
			return;
		} else {
			this.isValidRePassword = true;
		}
		if (this.repository.customer === null) {
			this.isValid = false;
			this.snackbarCtrl.open(this.translate.instant('sign_up.validation.user'), null, {
				duration: 5000,
			});
			return;
		}
		if (!this.repository.customer.isAnonymous) {
			this.isValid = false;
			this.snackbarCtrl.open(this.translate.instant('sign_up.validation.already_signed_in'), null, {
				duration: 5000,
			});
			return;
		}
		if (!this.agbChecked) {
			this.isValid = false;
			this.snackbarCtrl.open(this.translate.instant('sign_up.validation.agb'), null, {
				duration: 5000,
			});
			return;
		}
		this.isValid = true;
		this.isValidEmail = true;
	}

	async signUp() {
		this.validate();
		if (this.isValid && !this.loading) {
			this.loading = true;
			try {
				if (environment.isAspasiaBuild) {
					await Api.signUpWithCredentials(this.fullName, null, this.email, this.password, environment.customerGroup);
				} else {
					await Api.signUpWithCredentials(this.fullName, this.gender, this.email, this.password, environment.customerGroup);
				}
				await EmailConfirmationPage.navigate(this.router, true);
			} catch (e) {
				if (e.response.data.name === 'UserAlreadyExistsError') {
					this.isValidEmail = false;
					this.snackbarCtrl.open((this.translate.instant('axios_errors.user_already_exists_error')), null, {duration: 5000});
				} else {
					this.snackbarCtrl.open(Utils.axiosErrorToMessage(this.translate, e));
				}
			}
			this.loading = false;
		}
	}

	signIn() {
		SignInPage.navigate(this.router);
	}

	setEmailValid() {
		this.isValidEmail = true;
	}

	setNameValid() {
		this.isValidName = true;
	}

	setPasswordValid() {
		this.isValidPassword = true;
		this.isValidRePassword = true;
	}

	async signUpWithGoogle() {
		if (this.agbChecked && !this.loading) {
			this.loading = true;
			try {
				console.log('Starting signUp with Google');
				const result = await Api.signIn(AuthStrategy.GOOGLE, environment.customerGroup);
				console.log(result);
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}

	async signUpWithFacebook() {
		if (this.agbChecked && !this.loading) {
			this.loading = true;
			try {
				console.log('Starting signUp with Facebook');
				const result = await Api.signIn(AuthStrategy.FACEBOOK, environment.customerGroup);
				console.log(result);
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}

	async signUpWithApple() {
		if (this.agbChecked && !this.loading) {
			this.loading = true;
			try {
				console.log('Starting signUp with Apple');
				const result = await Api.signIn(AuthStrategy.APPLE, environment.customerGroup);
				console.log(result);
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}

	async openTerms() {
		await TosPage.show(this.modalCtrl);
	}

	goBack() {
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
}
