import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-show-loader-dialog',
	templateUrl: './show-loader-dialog.component.html',
	styleUrls: ['show-loader-dialog.component.scss'],
})
export class ShowLoaderDialogComponent implements OnInit {
	title: string;
	constructor() {}

	ngOnInit() {}
}
