import { Component } from '@angular/core';
import { RepositoryService } from '../../services/repository/repository.service';
import { SignInPage } from '../../pages/sign-in/sign-in.page';
import { Router } from '@angular/router';
import { SignUpPage } from '../../pages/sign-up/sign-up.page';
import { ModalController, PopoverController } from '@ionic/angular';
import { HomePage } from '../../pages/home/home.page';
import { MyOrdersPage } from '../../pages/my-orders/my-orders.page';
import { AccountPage } from '../../pages/account/account.page';
import { PrivacyPage } from '../../pages/privacy/privacy.page';
import { TosPage } from '../../pages/tos/tos.page';
import { ImpressumPage } from '../../pages/impressum/impressum.page';
import { FaqPage } from '../../pages/faq/faq.page';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import RepositoryDirective from '../../pages/repository-directive';
import { Api } from '../../../api/api';

@Component({
	selector: 'app-menu-popover',
	templateUrl: './menu-popover.component.html',
	styleUrls: ['menu-popover.component.scss'],
})
export class MenuPopoverComponent extends RepositoryDirective {
	constructor(
		public repository: RepositoryService,
		private router: Router,
		private popoverCtrl: PopoverController,
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private translate: TranslateService
	) {
		super(repository);
	}

	async signIn() {
		await this.popoverCtrl.dismiss();
		await SignInPage.navigate(this.router);
	}

	async signUp() {
		await this.popoverCtrl.dismiss();
		SignUpPage.navigate(this.router);
	}

	async mySpace() {
		await this.popoverCtrl.dismiss();
		await AccountPage.navigate(this.router);
	}

	async faq() {
		await this.popoverCtrl.dismiss();
		FaqPage.navigate(this.router);
	}

	async myOrders() {
		await this.popoverCtrl.dismiss();
		MyOrdersPage.navigate(this.router);
	}

	async logout() {
		try {
			await Api.logout();
		} catch (e) {}
		this.repository.customerAuth.emit(null);
		this.snackbarCtrl.open(this.translate.instant('menu_popover.logout_success'), null, {
			duration: 2000,
		});
		await this.popoverCtrl.dismiss();
	}

	async home() {
		await this.popoverCtrl.dismiss();
		await HomePage.navigate(this.router);
	}

	async privacy() {
		await this.popoverCtrl.dismiss();
		await PrivacyPage.show(this.modalCtrl);
	}

	async tos() {
		await this.popoverCtrl.dismiss();
		await TosPage.show(this.modalCtrl);
	}

	async impressum() {
		await this.popoverCtrl.dismiss();
		await ImpressumPage.show(this.modalCtrl);
	}

	dismiss() {
		this.popoverCtrl.dismiss();
	}
}
