import { Component, Input, OnDestroy } from '@angular/core';
import { RepositoryService } from '../../services/repository/repository.service';
import Utils from '../../../utils';
import Article from '../../../models/Article';
import { PreorderType } from '../../../enums/PreorderType';
import { OrderType } from '../../../enums/OrderType';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-item-selectable-article',
	templateUrl: './item-selectable-article.component.html',
	styleUrls: ['item-selectable-article.component.scss'],
})
export class ItemSelectableArticleComponent implements OnDestroy {
	@Input() article: Article;
	@Input() selected: number;
	@Input() preorderType: PreorderType;
	utils = Utils;
	currency: string;
	private readonly subscription: Subscription;

	constructor(public repository: RepositoryService) {
		this.currency = repository._order ? repository._order.currency : null;
		this.subscription = this.repository.order.subscribe(order => (this.currency = order ? order.currency : null));
	}

	get price(): number {
		return Utils.getPrice(this.article, OrderType.STANDARD, this.preorderType);
	}

	ngOnDestroy() {
		if (this.subscription && !this.subscription.closed) {
			this.subscription.unsubscribe();
		}
	}
}
