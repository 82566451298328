import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { IonInput, ModalController } from '@ionic/angular';
import { RepositoryService } from '../../services/repository/repository.service';
import { Router } from '@angular/router';
import Address from '../../../models/Address';
import Venue from '../../../models/Venue';
import { MatSnackBar } from '@angular/material/snack-bar';
import Utils from '../../../utils';
import { PreorderType } from '../../../enums/PreorderType';
import { AppComponent } from '../../app.component';
import { TranslateService } from '@ngx-translate/core';
import { MapsUtils } from '../../../utils/maps-utils';
import { MenuPage } from '../menu/menu.page';
import { CheckDeliveryRadiusModalComponent } from '../../components/check-delivery-radius-modal/check-delivery-radius-modal.component';
import AutocompletePrediction = google.maps.places.AutocompletePrediction;
import GeocoderResult = google.maps.GeocoderResult;
import RepositoryDirective from '../repository-directive';

@Component({
	selector: 'app-home',
	templateUrl: './home.page.html',
	styleUrls: ['home.page.scss'],
})
export class HomePage extends RepositoryDirective implements OnInit {
	static url = 'home';

	@ViewChild(IonInput, { static: true }) inputField: IonInput;
	@ViewChild(IonInput, { static: true }) pacContainer: HTMLElement;

	loading = true;
	venuesLoaded = false;
	searchTerm: string;

	venues: Venue[] = [];
	deliveryVenues: Venue[] = [];

	selectedVenue: Venue;
	deliveryVenuesAvailable = false;
	takeAwayVenuesAvailable = false;
	showVenues = true;
	preorderType: PreorderType;

	predictions: AutocompletePrediction[] = [];
	utils = Utils;

	constructor(
		protected repository: RepositoryService,
		private translate: TranslateService,
		private router: Router,
		private snackbarCtrl: MatSnackBar,
		private snackBar: MatSnackBar,
		private cdr: ChangeDetectorRef,
		private modalController: ModalController
	) {
		super(repository);
	}

	static async navigate(router: Router, repository?: RepositoryService) {
		if (repository) {
			// repository.resetVenue();
			repository.order.emit(null);
			repository.verifiedOrder.emit(null);
			repository.payment.emit(null);
		}

		await router.navigateByUrl(HomePage.url);
	}

	largeScreen(): boolean {
		return AppComponent.largeScreen;
	}

	ngOnInit() {
		super.ngOnInit();
		// this.repository.getAllVenues().then(venues => this.venues = venues);
	}

	ionViewDidEnter() {
		if (history.state.navigationId === 1) {
			this.repository.order.emit(null);
		}
		this.repository.verifiedOrder.emit(null);
	}

	async resetSearch() {
		(await this.inputField.getInputElement()).value = '';
		this.searchTerm = '';
		this.venuesLoaded = false;
		this.deliveryVenuesAvailable = false;
		this.takeAwayVenuesAvailable = false;
		this.predictions = [];
	}

	async fillInPlace(place: GeocoderResult) {
		this.loading = true;
		this.deliveryVenues = [];
		try {
			// const address = await MapsUtils.fillInPlace(this.translate, this.inputField, place, term => {
			// 	if (term) {
			// 		this.searchTerm = term;
			// 	}
			// 	return this.searchTerm;
			// });
			// if (!address) {
			// 	await this.executeSearch();
			// 	return;
			// }
			// await this.loadVenues(address);
			// if (this.deliveryVenues.map(ven => ven._id).indexOf(this.selectedVenue._id) >= 0) {
			// 	this.loading = true;
			// 	this.selectedVenue = await this.repository.getVenue(this.selectedVenue._id);
			// 	this.repository.createOrder(this.selectedVenue, address, PreorderType.DELIVERY);
			// 	await MenuPage.navigate(this.router);
			// 	this.loading = false;
			// }
		} catch (e) {
			this.snackBar.open(e, null, {
				duration: 2000,
			});
		}
		this.loading = false;
		this.cdr.detectChanges();
	}

	async loadVenues(address: Address) {
		this.takeAwayVenuesAvailable = false;
		this.deliveryVenuesAvailable = false;
		this.deliveryVenues = [];
		this.venuesLoaded = false;
		if (address.street === null || address.street === undefined) {
			this.address = null;
			this.loading = false;
			this.snackBar.open(this.translate.instant('home_page.street_error'), null, {
				duration: 2000,
			});
			this.cdr.detectChanges();
			return;
		}
		this.repository.address.emit(address);
		try {
			this.deliveryVenues = await this.repository.getVenuesByAddress(address);
			console.log(this.deliveryVenues.map(ven => ven.name));
			for (const venue of this.deliveryVenues) {
				this.takeAwayVenuesAvailable = this.takeAwayVenuesAvailable || Utils.venueAcceptsOrders(venue, PreorderType.TAKE_AWAY);
				this.deliveryVenuesAvailable =
					this.deliveryVenuesAvailable ||
					(((this.selectedVenue._id === venue._id &&
						venue.distance &&
						venue.distance <= venue.deliveryRadius &&
						venue.deliveryByRadius) ||
						venue.isPostalDelivery) &&
						Utils.venueAcceptsOrders(venue, PreorderType.DELIVERY));
			}
			this.venuesLoaded = true;
		} catch (e) {
			console.error(e);
			this.venuesLoaded = false;
		}
		this.loading = false;
		this.cdr.detectChanges();
	}

	async selectVenue(venue: Venue, preorderType: PreorderType, attempt: number = 0, prevError: any = null) {
		if (attempt > 5) {
			this.loading = false;
			this.snackbarCtrl.open(prevError, null, {
				duration: 2000,
			});
			return;
		}

		if (preorderType === PreorderType.TAKE_AWAY) {
			this.loading = true;
			await this.repository.getVenue(venue._id);
			this.repository.createOrder(venue, null, preorderType);
			await MenuPage.navigate(this.router);
			this.loading = false;
		} else if (preorderType === PreorderType.DELIVERY) {
			this.showVenues = false;
			const result: { venue: Venue; address: Address } = await CheckDeliveryRadiusModalComponent.show(
				this.modalController,
				this.venues[0]
			);

			if (result) {
				this.loading = true;
				console.log(result);
				await this.repository.getVenue(result.venue._id);
				this.repository.createOrder(result.venue, result.address, PreorderType.DELIVERY);
				await MenuPage.navigate(this.router);
				this.loading = false;
			}
			this.selectedVenue = venue;
		}
	}

	async takeAway() {
		await this.selectVenue(this.selectedVenue, PreorderType.TAKE_AWAY);
	}

	async executeSearch() {
		this.predictions = [];
		this.loading = true;
		try {
			const result = await MapsUtils.executeSearch(this.inputField);
			// await this.fillInPlace(result);
		} catch (e) {}
		this.loading = false;
		this.cdr.detectChanges();
	}
}
