import { Component, EventEmitter, Input, Output } from '@angular/core';
import Venue from '../../../models/Venue';
import { WorkingHoursModalComponent } from '../working-hours-modal/working-hours-modal.component';
import { ModalController } from '@ionic/angular';
import Utils from '../../../utils';
import { PreorderType } from '../../../enums/PreorderType';
import moment, { Moment } from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-venue-suggestion',
	templateUrl: './venue-suggestion.component.html',
	styleUrls: ['venue-suggestion.component.scss'],
})
export class VenueSuggestionComponent {
	@Input() type: PreorderType;
	@Output() venuePick = new EventEmitter<{ venue: Venue; type: PreorderType }>();
	utils = Utils;
	isVenueOpen = false;
	pt = PreorderType;
	@Input()
	overridePostalDelivery = false;
	acceptsDelivery = false;
	firstDeliveryTime: string = null;
	acceptsTakeaway = false;
	firstTakeAwayTime: string = null;
	@Input()
	isDeliveryAlternative = false;
	@Input()
	selected = false;

	constructor(private modalCtrl: ModalController, private translate: TranslateService) {}

	private _venue: Venue;

	get venue(): Venue {
		return this._venue;
	}

	@Input()
	set venue(venue: Venue) {
		this._venue = venue;
		this.acceptsDelivery = false;
		this.acceptsTakeaway = false;
		this.firstDeliveryTime = null;
		this.firstTakeAwayTime = null;
		this.isVenueOpen = false;
		if (venue === undefined || venue === null) {
			return;
		}
		this.isVenueOpen = Utils.isVenueOpen(venue);
		Utils.getSlots(venue, PreorderType.TAKE_AWAY).then(slots => {
			this.acceptsTakeaway = slots.length > 0 && Utils.venueAcceptsOrders(venue, PreorderType.TAKE_AWAY);
			if (this.acceptsTakeaway) {
				this.firstTakeAwayTime = this.timeToText(venue, moment(slots[0].time));
			}
		});
		Utils.getSlots(venue, PreorderType.DELIVERY).then(slots => {
			this.acceptsDelivery = slots.length > 0 && Utils.venueAcceptsOrders(venue, PreorderType.DELIVERY, this.overridePostalDelivery);
			if (this.acceptsDelivery) {
				this.firstDeliveryTime = this.timeToText(venue, moment(slots[0].time));
			}
		});
	}

	selectVenue(venue: Venue, type: PreorderType) {
		if (!this.accepts(type)) {
			return;
		}
		this.venuePick.emit({ venue, type });
	}

	accepts(type: PreorderType): boolean {
		switch (type) {
			case PreorderType.DELIVERY:
				return this.acceptsDelivery;
			case PreorderType.TAKE_AWAY:
				return this.acceptsTakeaway;
			default:
				return false;
		}
	}

	openModal(venue: Venue) {
		WorkingHoursModalComponent.show(this.modalCtrl, venue, this.type);
	}

	timeToText(venue: Venue, time: Moment | null) {
		if (time && venue.slot) {
			const now = moment().add(
				Math.abs((moment().minutes() % venue.slot.interval) - venue.slot.interval) % venue.slot.interval,
				'minutes'
			);
			const roundedTime = time.add(
				Math.abs((time.minutes() % venue.slot.interval) - venue.slot.interval) % venue.slot.interval,
				'minutes'
			);
			const relevantTime = now.isAfter(roundedTime) ? now : roundedTime;
			const isToday = now.dayOfYear() === relevantTime.dayOfYear() && now.year() === relevantTime.year();
			return (isToday ? '' : this.translate.instant('venue_suggestion.tomorrow') + ' ') + relevantTime.format('HH:mm');
		} else {
			return null;
		}
	}
}
