export enum OrderStatus {
	IN_PREPARATION = 'in_preparation',
	CREATED = 'created',
	READY = 'ready',
	DONE = 'done',
	IN_PAYMENT = 'in_payment',
	CANCELLED_BY_WAITER = 'cancelled_by_waiter',
	CANCELLED_BY_CUSTOMER = 'cancelled_by_customer',
	AWAITING_CONFIRMATION = 'awaiting_confirmation',
	NONE = 'none',
}
