import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import Utils from '../../../utils';

@Component({
	selector: 'app-register-modal',
	templateUrl: '../../../projects/aspasia/components/register-modal/register-modal.component.html',
	styleUrls: ['../../../projects/aspasia/components/register-modal/register-modal.component.scss'],
})
export class RegisterModalComponent implements OnInit {
	constructor(private modalController: ModalController) {}

	static async show(modalCtrl: ModalController): Promise<{ status: boolean }> {
		const modal = await modalCtrl.create({
			component: RegisterModalComponent,
			cssClass: 'reg-modal',
			backdropDismiss: false,
		});
		await modal.present();
		const result = await modal.onDidDismiss();
		await Utils.sleep(100);
		return result.data;
	}

	ngOnInit() {}

	async dismiss(status: boolean) {
		await this.modalController.dismiss({ status });
	}
}
