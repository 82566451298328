import { Component, Input } from '@angular/core';
import { OrderStatus } from '../../../enums/OrderStatus';
import * as moment from 'moment';
import Utils from '../../../utils';
import Order from '../../../models/Order';
import { PreorderType } from '../../../enums/PreorderType';
import { RepositoryService } from '../../services/repository/repository.service';
import { Router } from '@angular/router';
import { OrderPage } from '../../pages/order/order.page';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { OrderUtils } from '../../../utils/order-utils';

@Component({
	selector: 'app-my-order',
	templateUrl: './my-order.component.html',
	styleUrls: ['../../../projects/aspasia/components/my-order/my-order.component.scss'],
})
export class MyOrderComponent {
	@Input() order: Order;

	showQr = false;
	moment = moment;

	os = OrderStatus;
	pt = PreorderType;

	Utils = Utils;
	OrderUtils = OrderUtils;

	loading = false;

	constructor(
		private repository: RepositoryService,
		private router: Router,
		private snackbarCtrl: MatSnackBar,
		private translate: TranslateService
	) {}

	async reorder() {
		this.loading = true;
		try {
			await this.repository.reorder(this.translate, this.order);
			await OrderPage.navigate(this.router);
		} catch (e) {
			console.error(e);
			this.snackbarCtrl.open(e.message ? e.message : this.translate.instant('my_order.reorder_error'), null, {
				duration: 2000,
			});
		}
		this.loading = false;
	}
}
