import { AnalyticsService } from './../../services/analytic/analytics.service';
import { Component, OnInit } from '@angular/core';
import { get } from 'scriptjs';
import { Api, PAYMENT_SERVICE } from '../../../api/api';
import { ModalController } from '@ionic/angular';
import Payment from '../../../models/Payment';
import { environment } from '../../../environments/environment';
import Utils from '../../../utils';
import { PaymentMethod } from '../../../enums/PaymentMethod';
import Venue from '../../../models/Venue';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import Order from '../../../models/Order';
import { PaymentStatus } from '../../../models/PaymentStatus';
import Customer from '../../../models/Customer';
import { ShowLoaderDialogComponent } from '../show-loader-dialog/show-loader-dialog.component';

export enum PaymentModalResult {
	SUCCESS,
	ERROR,
	DISMISS,
}

// tslint:disable-next-line:prefer-const
declare var wpwlOptions: any;

// @ts-ignore
const applePayAvailable = !!window.ApplePaySession;

@Component({
	selector: 'app-payment-modal',
	templateUrl: './payment-modal.component.html',
	styleUrls: ['payment-modal.component.scss'],
})
export class PaymentModalComponent implements OnInit {
	payment: Payment;
	venue: Venue;
	order: Order;
	customer: Customer;
	loading = false;
	paymentMethods: PaymentMethod[] = [];
	selectedPaymentMethod: PaymentMethod = null;
	pm = PaymentMethod;
	vrUrl = environment.baseUrl + PAYMENT_SERVICE + 'vr/pay/';
	promoCodeDialog: HTMLIonModalElement = null;

	constructor(private modalCtrl: ModalController, private analytics: AnalyticsService) {}

	static async show(
		modalCtrl: ModalController,
		venue: Venue,
		payment: Payment,
		order: Order,
		customer: Customer
	): Promise<{ result: PaymentModalResult; error: any; payment: Payment }> {
		const paymentMethods: PaymentMethod[] = [];
		if (venue.ccEnabled) {
			paymentMethods.push(PaymentMethod.CREDIT_CARD);
		}
		if (venue.gpEnabled) {
			paymentMethods.push(PaymentMethod.GPAY);
		}
		if (venue.apEnabled && applePayAvailable) {
			paymentMethods.push(PaymentMethod.APAY);
		}
		if (venue.sbEnabled) {
			paymentMethods.push(PaymentMethod.SOFORTBANKING);
		}
		if (venue.ppEnabled) {
			paymentMethods.push(PaymentMethod.PAYPAL);
		}
		const modal = await modalCtrl.create({
			component: PaymentModalComponent,
			componentProps: {
				payment,
				paymentMethods,
				venue,
				order,
				customer,
			},
		});
		await modal.present();
		const result = await modal.onDidDismiss();
		await Utils.sleep(100);
		return result.data;
	}

	ngOnInit() {
		this.analytics.paymentModalOpened();
	}

	async loadPayment() {
		if (this.loading) {
			return;
		}
		this.loading = true;
		this.payment._id = null;
		this.payment.method = this.selectedPaymentMethod;

		try {
			switch (this.selectedPaymentMethod) {
				case PaymentMethod.PAYPAL:
					await this.loadPaypal();
					break;
				case PaymentMethod.CREDIT_CARD:
					await this.loadCreditCard();
					break;
				case PaymentMethod.SOFORTBANKING:
					await this.loadKlarna();
					break;
				case PaymentMethod.GPAY:
					await this.loadGpay();
					break;
				case PaymentMethod.APAY:
					await this.loadApay();
					break;
			}
		} catch (e) {
			console.error(e);
		}
		this.loading = false;
		this.analytics.paymentLoaded(this.order, this.payment.method);
	}

	async loadCreditCard(): Promise<any> {
		if (!this.venue.vrPaymentEnabled && this.venue.ppFullIntegrationEnabled) {
			return await this.loadPaypalCreditCard();
		}
		this.payment = await Api.createPayment(this.payment);
		wpwlOptions = {
			locale: 'de',
			style: 'plain',
			billingAddress: this.getBillingAddress(),
			mandatoryBillingFields: {
				country: true,
				state: false,
				city: true,
				postcode: true,
				street1: true,
			},
			onAfterSubmit: () => {
				this.openDialog();
			},
			onLoadThreeDIframe: () => {
				this.promoCodeDialog.dismiss();
			},
		};
		console.log({ wpwlOptions });
		return new Promise<void>((resolve, reject) => {
			get(
				`${environment.VR_URL}?checkoutId=${this.payment.vrPayment.checkoutId}`,
				() => resolve(),
				() => reject('Could not load script')
			);
		});
	}

	getBillingAddress(): { country: string; city: string; postcode: string; street1: string } {
		if (this.customer && this.customer.street) {
			return {
				country: this.customer.country.toUpperCase(),
				city: this.customer.city,
				postcode: this.customer.postalCode,
				street1: this.customer.street + ' ' + this.customer?.number,
			};
		}
		return {
			country: this.order.preorder.country.toUpperCase(),
			city: this.order.preorder.city,
			postcode: this.order.preorder.postalCode,
			street1: this.order.preorder.street + ' ' + this.order.preorder.number,
		};
	}

	async loadGpay(): Promise<any> {
		this.payment = await Api.createPayment(this.payment);
		wpwlOptions = {
			locale: 'de',
			style: 'plain',
			googlePay: {
				gatewayMerchantId: this.payment.merchantId,
				allowedAuthMethods: ['PAN_ONLY'],
				allowedCardNetworks: ['MASTERCARD', 'VISA'],
				merchantName: this.venue.name,
				merchantId: environment.GPAY.MERCHANT_ID,
				gateway: environment.GPAY.GATEWAY,
				assuranceDetailsRequired: true,
			},
		};
		return new Promise<any>((resolve, reject) => {
			get(
				`${environment.VR_URL}?checkoutId=${this.payment.vrPayment.checkoutId}`,
				() => resolve(),
				() => reject('Could not load script')
			);
		});
	}

	async loadApay(): Promise<any> {
		this.payment = await Api.createPayment(this.payment);
		wpwlOptions = {
			locale: 'de',
			style: 'plain',
			createCheckout: () => {
				return this.payment.vrPayment.checkoutId;
			},
			applePay: {
				version: 3,
				displayName: this.venue.name,
				total: this.payment.sum,
				currencyCode: this.payment.currency,
				checkAvailability: 'canMakePayments',
				merchantIdentifier: environment.APAY_MERCHANT_ID,
				style: 'black',
				merchantCapabilities: ['supports3DS'],
				supportedNetworks: ['masterCard', 'visa'],
				shippingType: 'storePickup',
				onCancel: () => {
					console.log('onCancel');
				},
				onPaymentAuthorized: payment => {
					console.log('onPaymentAuthorized payment: ' + JSON.stringify(payment));
					this.payment.status = PaymentStatus.done;
					this.analytics.purchase(this.order, this.payment);
					this.modalCtrl.dismiss({
						result: PaymentModalResult.SUCCESS,
						payment: this.payment,
					});
				},
			},
		};
		return new Promise<any>((resolve, reject) => {
			get(
				`${environment.VR_URL}?checkoutId=${this.payment.vrPayment.checkoutId}`,
				() => {
					resolve();
				},
				() => {
					reject('Could not load script');
				}
			);
		});
	}

	loadPaypalCreditCard(): Promise<any> {
		return new Promise<any>(async (resolve, reject) => {
			this.payment.method = PaymentMethod.PAYPAL;
			this.payment = await Api.createPayment(this.payment);
			const head = document.getElementsByTagName('head')[0];
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = environment.PAYPAL.JS_URL + this.payment.currency + '&merchant-id=' + this.payment.merchantId;
			script.setAttribute('data-partner-attribution-id', environment.PAYPAL.BN_CODE);
			head.appendChild(script);
			script.onerror = () => {
				reject('Could not load script');
			};
			script.onload = () => {
				// @ts-ignore
				const button = paypal.Buttons({
					intent: 'capture',
					commit: true,
					vault: false,
					enableStandardCardFields: true,
					createOrder: (data, actions) => {
						return this.payment.paypal.orderId;
					},
					onApprove: data => {
						this.loading = true;
						Api.finishPaypalPayment(data.orderID)
							.then(async result => {
								this.analytics.purchase(this.order, result);
								await this.modalCtrl.dismiss({
									result: PaymentModalResult.SUCCESS,
									payment: result,
								});
							})
							.finally(() => (this.loading = false));
					},
					onError: err => {
						this.loading = true;
						this.modalCtrl.dismiss({
							result: PaymentModalResult.ERROR,
							error: err,
						});
					},
					// @ts-ignore
					fundingSource: paypal.FUNDING.CARD,
				});
				if (button.isEligible()) {
					button.render('#paypal-cc-button');
				}
				resolve();
			};
		});
	}

	loadPaypal(): Promise<any> {
		return new Promise<any>(async (resolve, reject) => {
			this.payment = await Api.createPayment(this.payment);
			console.log(this.payment);
			const head = document.getElementsByTagName('head')[0];
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = environment.PAYPAL.JS_URL + this.payment.currency + '&merchant-id=' + this.payment.merchantId;
			console.log(script.src);
			script.setAttribute('data-partner-attribution-id', environment.PAYPAL.BN_CODE);
			head.appendChild(script);
			script.onerror = () => {
				reject('Could not load script');
			};
			script.onload = () => {
				// @ts-ignore
				const button = paypal.Buttons({
					intent: 'capture',
					commit: true,
					vault: false,
					enableStandardCardFields: true,
					createOrder: (data, actions) => {
						return this.payment.paypal.orderId;
					},
					onApprove: data => {
						this.loading = true;
						Api.finishPaypalPayment(data.orderID)
							.then(async result => {
								this.analytics.purchase(this.order, result);
								await this.modalCtrl.dismiss({
									result: PaymentModalResult.SUCCESS,
									payment: result,
								});
							})
							.finally(() => (this.loading = false));
					},
					onError: err => {
						this.loading = true;
						this.modalCtrl.dismiss({
							result: PaymentModalResult.ERROR,
							error: err,
						});
					},
					// @ts-ignore
					fundingSource: paypal.FUNDING.PAYPAL,
				});
				if (button.isEligible()) {
					button.render('#paypal-button');
				}
				resolve();
			};
		});
	}

	loadKlarna(): Promise<any> {
		return new Promise<any>(async (resolve, reject) => {
			this.payment.method = PaymentMethod.PAYPAL;
			this.payment = await Api.createPayment(this.payment);
			const head = document.getElementsByTagName('head')[0];
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = environment.PAYPAL.JS_URL + this.payment.currency + '&merchant-id=' + this.payment.merchantId;
			script.setAttribute('data-partner-attribution-id', environment.PAYPAL.BN_CODE);
			head.appendChild(script);
			script.onerror = () => {
				reject('Could not load script');
			};
			script.onload = () => {
				// @ts-ignore
				const button = paypal.Buttons({
					intent: 'capture',
					commit: true,
					vault: false,
					enableStandardCardFields: true,
					createOrder: (data, actions) => {
						return this.payment.paypal.orderId;
					},
					onApprove: data => {
						this.loading = true;
						Api.finishPaypalPayment(data.orderID)
							.then(async result => {
								this.analytics.purchase(this.order, result);
								await this.modalCtrl.dismiss({
									result: PaymentModalResult.SUCCESS,
									payment: result,
								});
							})
							.finally(() => (this.loading = false));
					},
					onError: err => {
						this.loading = true;
						this.modalCtrl.dismiss({
							result: PaymentModalResult.ERROR,
							error: err,
						});
					},
					// @ts-ignore
					fundingSource: paypal.FUNDING.SOFORT,
				});
				if (button.isEligible()) {
					button.render('#klarna-button');
				}
				resolve();
			};
		});
	}

	dismiss() {
		if (this.loading) {
			return;
		}
		this.modalCtrl.dismiss({
			result: PaymentModalResult.DISMISS,
		});
	}
	formCallback(data: any) {
		console.log('formCallback', data);
	}
	async openDialog() {
		this.promoCodeDialog = await this.modalCtrl.create({
			component: ShowLoaderDialogComponent,
			cssClass: 'checkout-modal-full',
			backdropDismiss: false,
			componentProps: {
				title: 'Bezahlung wird bearbeitet',
			},
		});
		await this.promoCodeDialog.present();
	}
}
