import Order from './Order';
import ArticleGroup from './ArticleGroup';
import Payment from './Payment';
import Customer from './Customer';
import * as moment from 'moment';
import { sanitizeId } from '../api/api';
import { OrderType } from '../enums/OrderType';
import Preorder from './Preorder';
import { PreorderType } from '../enums/PreorderType';

export function orderBody(order: Order) {
	let promoCode: string = null;
	if (order.promoCode) {
		if (order.promoCode._id) {
			promoCode = order.promoCode._id;
		} else {
			promoCode = order.promoCode;
		}
	}
	// @ts-ignore
	order.preorder.deliveryFeeNet = undefined;
	return {
		_id: order._id ? order._id : undefined,
		venue: order.venue,
		status: order.status,
		type: order.type,
		preorder: order.type === OrderType.PREORDER ? preorderBody(order.preorder) : undefined,
		orderAt: order.orderAt ? moment(order.orderAt).utc().seconds(0).milliseconds(0).toISOString() : undefined,
		asap: order.asap,
		flavor: order.flavor,
		orderedArticles: order.orderedArticles.map(value => ArticleGroupConverter.toBody(value)),
		table: order.table ? order.table : undefined,
		userUid: sanitizeId(order.userUid),
		terminalorder: order.type === OrderType.TERMINAL ? order.terminalorder : undefined,
		loyaltyNumber: order.loyaltyNumber ? order.loyaltyNumber : undefined,
		promoCode: promoCode ? promoCode : undefined,
	};
}

export function preorderBody(preorder: Preorder) {
	return {
		type: preorder.type,
		personCount: 1,
		name: preorder.name,
		email: preorder.email,
		phone: preorder.phone,
		note: preorder.note,
		postalCode: preorder.postalCode,
		companyName: preorder.companyName,
		floor: preorder.floor,
		country: preorder.country,
		city: preorder.city,
		street: preorder.street,
		number: preorder.number,
		subcard: preorder.subcard,
		licenseNumber: preorder.type === PreorderType.PARK_COLLECT ? preorder.licenseNumber : undefined,
		vehicleBrand: preorder.type === PreorderType.PARK_COLLECT ? preorder.vehicleBrand : undefined,
		vehicleColor: preorder.type === PreorderType.PARK_COLLECT ? preorder.vehicleColor : undefined,
	};
}

export function customerBody(customer: Customer) {
	return {
		_id: customer._id,
		name: customer.name,
		street: customer.street,
		city: customer.city,
		postalCode: customer.postalCode,
		companyName: customer.companyName,
		preferredVenue: customer.preferredVenue,
		floor: customer.floor,
		country: customer.country,
		subcard: customer.subcard,
		loyaltyNumber: customer.loyaltyNumber ? customer.loyaltyNumber : customer.subcard,
		number: customer.number,
		userUid: sanitizeId(customer.uid),
		gender: customer.gender,
		phone: customer.phoneCountry ? customer.phoneCountry.tel.substr(1) + customer.phone : customer.phone,
		email: customer.email,
		state: customer.state
	};
}

export function paymentBody(payment: Payment) {
	return {
		_id: payment._id,
		orders: payment.orders.map(order => {
			if (order.hasOwnProperty('_id')) {
				return order._id;
			} else {
				return order;
			}
		}),
		response: payment && payment.response ? JSON.parse(JSON.stringify(payment.response)) : null,
		session: payment.session,
		status: payment.status,
		method: payment.method,
		sum: payment.sum,
		transactionType: payment.transactionType,
	};
}

export class ArticleGroupConverter {
	static toBody(articleGroup: ArticleGroup) {
		return {
			article: articleGroup.article._id,
			quantity: articleGroup.quantity,
			groups: articleGroup.groups.map(value => {
				return {
					group: value.group,
					article: value.article._id,
					quantity: value.quantity,
					dependsOn: value.dependsOn,
					dependency: value.dependency,
					dependencyNumber: value.dependencyNumber,
				};
			}),
			isRecommendedRecipe: articleGroup.isRecommendedRecipe ? articleGroup.isRecommendedRecipe : false,
			freeArticle: articleGroup.freeArticle ? articleGroup.freeArticle : false,
			isPromo: articleGroup.isPromo ? articleGroup.isPromo : false,
			note: articleGroup.note || articleGroup.note === '' ? articleGroup.note : undefined,
		};
	}
}
