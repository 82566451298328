import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HomePage } from '../home/home.page';
import { MenuPage } from '../menu/menu.page';
import { RepositoryService } from '../../services/repository/repository.service';
import { ModalController } from '@ionic/angular';
import Venue from '../../../models/Venue';
import Address from '../../../models/Address';
import Utils from '../../../utils';

@Component({
	selector: 'app-impressum',
	templateUrl: './impressum.page.html',
	styleUrls: ['impressum.page.scss'],
})
export class ImpressumPage {
	static url = 'impressum';

	constructor(private repository: RepositoryService, private router: Router, private modalCtrl: ModalController) {}

	static navigate(router: Router) {
		router.navigateByUrl(ImpressumPage.url);
	}

	static async show(modalCtrl: ModalController) {
		const modal = await modalCtrl.create({
			component: ImpressumPage,
			cssClass: 'privacy-modal',
		});
		await modal.present();
	}

	async goBack() {
		await this.modalCtrl.dismiss()
	}
}
