import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PreorderType } from 'src/enums/PreorderType';
import Venue from 'src/models/Venue';
import Utils from 'src/utils';

@Component({
	selector: 'app-select-order-type-modal',
	templateUrl: './select-order-type-modal.component.html',
	styleUrls: ['select-order-type-modal.component.scss'],
})
export class SelectOrderTypeModalComponent {
	preorderTypesArr = [PreorderType.TAKE_AWAY, PreorderType.DELIVERY];
	constructor(private modalCtrl: ModalController) {}

	static async show(modalCtrl: ModalController, venue: Venue): Promise<PreorderType> {
		const modal = await modalCtrl.create({
			component: SelectOrderTypeModalComponent,
			cssClass: 'delivery-modal',
			componentProps: {
				selectedVenue: venue,
			},
		});
		await modal.present();
		const result = await modal.onDidDismiss();
		await Utils.sleep(100);
		return result.data;
	}
	dismiss() {
		this.modalCtrl.dismiss();
	}
	selectedPreorderType(type: PreorderType) {
		this.modalCtrl.dismiss(type);
	}
}
