import { ChangeDetectorRef, Component } from '@angular/core';
import { Api } from '../../../api/api';
import { RepositoryService } from '../../services/repository/repository.service';
import { Router } from '@angular/router';
import { PaymentStatus } from '../../../models/PaymentStatus';
import 'firebase/auth';
import * as moment from 'moment';
import Order from '../../../models/Order';
import { environment } from '../../../environments/environment';
import { HomePage } from '../home/home.page';
import { MenuPage } from '../menu/menu.page';
import RepositoryDirective from '../repository-directive';

@Component({
	selector: 'app-my-orders',
	templateUrl: '../../../projects/aspasia/pages/my-orders/my-orders.page.html',
	styleUrls: ['../../../projects/aspasia/pages/my-orders/my-orders.page.scss'],
})
export class MyOrdersPage extends RepositoryDirective {
	static url = 'my-orders';

	orders: Order[] = [];
	loading = false;

	constructor(public repository: RepositoryService, private cdr: ChangeDetectorRef, private router: Router) {
		super(repository);
	}

	get openOrders(): Order[] {
		return this.orders.filter(value => moment(value.orderAt).add(30, 'minutes').isSameOrAfter(moment()));
	}

	get doneOrders(): Order[] {
		return this.orders.filter(value => moment(value.orderAt).add(30, 'minutes').isBefore(moment()));
	}

	static navigate(router: Router) {
		router.navigateByUrl(MyOrdersPage.url);
	}

	onCustomer() {
		super.onCustomer();
		this.loadOrders();
	}

	loadOrders() {
		this.orders = [];
		if (!this.customer) {
			console.log('user not loaded');
			this.cdr.detectChanges();
			return;
		}
		Api.getCustomerOrders(this.customer.uid)
			.then(res => {
				const orders = res.data.withPayment.map(payment => {
					payment.orders = payment.orders
						.map(order => {
							order.paymentId = payment._id;
							order.paymentStatus = payment.status;
							order.paymentMethod = payment.method;
							return order as Order;
						})
						.filter(order => order.paymentStatus === PaymentStatus.reserved || order.paymentStatus === PaymentStatus.done);
					return payment.orders;
				});
				if (orders.length > 0) {
					this.orders = orders
						.reduce((previousValue, currentValue) => previousValue.concat(currentValue))
						// only normal orders no refunded
						.filter(order => order.referenceOrder === null || order.referenceOrder === undefined || order.referenceOrder === '')
						.reverse();
				}
				this.loading = false;
				this.cdr.detectChanges();
			})
			.catch(err => {
				console.log(err);
				this.loading = false;
				this.cdr.detectChanges();
			});
	}

	goBack() {
		if (environment.isAspasiaBuild) {
			MenuPage.navigate(this.router);
			return;
		}
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
}
