import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MapsUtils } from '../../../utils/maps-utils';
import { PreorderType } from '../../../enums/PreorderType';
import Venue from '../../../models/Venue';
import Utils from '../../../utils';
import { IonInput, ModalController, Platform } from '@ionic/angular';
import Address from '../../../models/Address';
import { RepositoryService } from '../../services/repository/repository.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppComponent } from '../../app.component';
import { CustomAddressModalComponent } from '../custom-address-modal/custom-address-modal.component';
import AutocompletePrediction = google.maps.places.AutocompletePrediction;
import GeocoderResult = google.maps.GeocoderResult;
import RepositoryDirective from '../../pages/repository-directive';
import { ValidationUtils } from '../../../utils/validation-utils';

@Component({
	selector: 'app-check-delivery-radius-modal',
	templateUrl: './check-delivery-radius-modal.component.html',
	styleUrls: ['check-delivery-radius-modal.component.scss'],
})
export class CheckDeliveryRadiusModalComponent extends RepositoryDirective implements OnInit {
	constructor(
		protected repository: RepositoryService,
		private translate: TranslateService,
		private router: Router,
		private snackbarCtrl: MatSnackBar,
		private snackBar: MatSnackBar,
		private cdr: ChangeDetectorRef,
		private modalCtrl: ModalController,
		private platform: Platform
	) {
		super(repository);
	}

	@ViewChild(IonInput, { static: true }) inputField: IonInput;
	@Input() selectedVenue: Venue;

	loading = false;
	venuesLoaded = false;
	searchTerm: string;
	showEmpty = false;
	geoState;

	suggestedPlace: GeocoderResult;
	deliveryVenueAvailable = false;

	predictions: AutocompletePrediction[] = [];
	utils = Utils;
	MapUtils = MapsUtils;

	static async show(modalCtrl: ModalController, venue: Venue): Promise<{ venue: Venue; address: Address }> {
		console.log('show');
		const modal = await modalCtrl.create({
			component: CheckDeliveryRadiusModalComponent,
			cssClass: 'delivery-modal',
			componentProps: {
				selectedVenue: venue,
			},
		});
		await modal.present();
		const result = await modal.onDidDismiss();
		await Utils.sleep(100);
		return result.data;
	}
	async ngOnInit() {
		if (this.platform.is('ios')) {
			navigator.geolocation.getCurrentPosition(
				i => (this.geoState = 'granted'),
				i => {
					this.geoState = 'denied';
					this.loading = false;
				}
			);
		} else {
			navigator.permissions.query({ name: 'geolocation' }).then(({ state }) => {
				this.geoState = state;
				if (state === 'prompt' || state === 'denied') {
					this.loading = false;
				}
			});
		}
		MapsUtils.getUserGeocode().then(result => {
			this.suggestedPlace = result;
		});

		await this.initAutocomplete();
		super.ngOnInit();
	}

	largeScreen(): boolean {
		return AppComponent.largeScreen;
	}

	onCustomer() {
		super.onCustomer();
		if (this.customer) {
			this.loadAddress(this.customer);
		}
	}

	async loadAddress(address: Address) {
		this.loading = true;
		await this.fillInPlace(await MapsUtils.getPlace(MapsUtils.titleOfSuggestion(address)));
		this.loading = false;
		this.cdr.detectChanges();
	}

	async loadPlace(pred: AutocompletePrediction) {
		this.loading = true;
		await this.fillInPlace(await MapsUtils.getPlace(pred.description));
		this.loading = false;
		this.cdr.detectChanges();
	}

	async resetSearch() {
		(await this.inputField.getInputElement()).value = '';
		this.searchTerm = '';
		this.venuesLoaded = false;
		this.deliveryVenueAvailable = false;
		this.predictions = [];
	}

	async loadVenues(address: Address) {
		this.deliveryVenueAvailable = false;
		this.venuesLoaded = false;
		if (address.street === null || address.street === undefined) {
			this.address = null;
			this.loading = false;
			this.snackBar.open(this.translate.instant('home_page.street_error'), null, {
				duration: 2000,
			});
			this.cdr.detectChanges();
			return;
		}
		try {
			const foundVenue = (await this.repository.getVenuesByAddress(address)).find(
				ven => ven._id === this.selectedVenue._id && Utils.venueAcceptsOrders(ven, PreorderType.DELIVERY)
			);
			this.deliveryVenueAvailable = foundVenue !== undefined;
			this.venuesLoaded = true;
		} catch (e) {
			this.venuesLoaded = false;
		}
		this.loading = false;
		this.cdr.detectChanges();
	}

	async fillInPlace(place: GeocoderResult) {
		this.loading = true;
		try {
			const address = await MapsUtils.fillInPlace(this.translate, this.inputField, place, term => {
				if (term) {
					this.searchTerm = term;
				}
				return this.searchTerm;
			});
			if (!address) {
				await this.executeSearch();
				return;
			}
			this.address = address;
			await this.loadVenues(address);
			this.loading = true;
		} catch (e) {
			this.snackBar.open(e, null, {
				duration: 2000,
			});
		}
		this.loading = false;
		this.cdr.detectChanges();
	}

	async dismiss(address: Address) {
		this.modalCtrl.dismiss({
			selectedVenue: this.selectedVenue,
			address,
		});
	}

	async executeSearch() {
		this.predictions = [];
		this.loading = true;
		try {
			const result = await MapsUtils.executeSearch(this.inputField);
			await this.fillInPlace(result);
		} catch (e) {}
		this.loading = false;
		this.cdr.detectChanges();
	}

	async initAutocomplete() {
		this.showEmpty = false;
		await MapsUtils.initAutocomplete(this.inputField, predictions => {
			this.predictions = predictions;
			Utils.sleep(250);
			this.showEmpty = predictions.length === 0;
			this.cdr.detectChanges();
		});
		this.loading = false;
	}

	async delivery() {
		await this.modalCtrl.dismiss({ venue: this.selectedVenue, address: this.address });
	}

	async showAddressModal() {
		const result: { address: GeocoderResult | null } = await CustomAddressModalComponent.show(this.modalCtrl);

		if (result.address) {
			this.fillInPlace(result.address as GeocoderResult);
		}
	}
	close() {
		this.modalCtrl.dismiss();
	}
}
