import { Component, OnInit } from '@angular/core';
import { RepositoryService } from '../../services/repository/repository.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import Utils from '../../../utils';
import { MenuPage } from '../menu/menu.page';
import { PreorderType } from '../../../enums/PreorderType';
import { AppComponent } from '../../app.component';
import { TranslateService } from '@ngx-translate/core';
import { OrderUtils } from '../../../utils/order-utils';
import RepositoryDirective from '../repository-directive';

@Component({
	selector: 'app-order',
	templateUrl: './order.page.html',
	styleUrls: ['order.page.scss'],
})
export class OrderPage extends RepositoryDirective implements OnInit {
	static url = 'order';
	static failUrlWithPaymentParam = 'payment/fail/:payment';
	static cancelUrlWithPaymentParam = 'payment/cancel/:payment';
	static paymentFailUrl = 'payment/fail';
	static paymentCancelUrl = 'payment/cancel';

	moment = moment;

	loading = false;

	utils = Utils;
	pt = PreorderType;

	constructor(
		private translate: TranslateService,
		protected repository: RepositoryService,
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private router: Router,
		private route: ActivatedRoute
	) {
		super(repository);
	}

	static navigate(router: Router) {
		return router.navigateByUrl(AppComponent.largeScreen ? MenuPage.url : OrderPage.url);
	}

	ngOnInit(): void {
		super.ngOnInit();
		switch (this.route.snapshot.routeConfig.path) {
			case OrderPage.url:
				break;
			case OrderPage.paymentCancelUrl:
				this.snackbarCtrl.open(this.translate.instant('order.payment_cancel'), null, {
					duration: 2000,
				});
				break;
			case OrderPage.paymentFailUrl:
				this.snackbarCtrl.open(this.translate.instant('order.payment_fail'), null, {
					duration: 5000,
				});
				break;
		}
	}

	backToMenu() {
		MenuPage.navigate(this.router);
	}

	totalPrice(): string {
		return Utils.numberToCurrency(OrderUtils.orderTotalPrice(this.order, true, true), this.order.currency);
	}
}
