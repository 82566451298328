import { Component, EventEmitter, Input, Output } from '@angular/core';
import OptionGroup from '../../../models/OptionGroup';
import ArticleOption from '../../../models/ArticleOption';
import { DisplayIdentifier } from '../../enums/DisplayIdentifier';
import Article from '../../../models/Article';
import Utils from '../../../utils';
import { PreorderType } from '../../../enums/PreorderType';
import { OrderType } from '../../../enums/OrderType';
import { numberD } from 'src/utils/utils';
import { ValidationUtils } from 'src/utils/validation-utils';
import { OrderUtils } from 'src/utils/order-utils';

@Component({
	selector: 'app-article-option-group-single',
	templateUrl: './article-option-group-single.component.html',
	styleUrls: ['article-option-group-single.component.scss'],
})
export class ArticleOptionGroupSingleComponent {
	@Input() optionGroup: OptionGroup;
	@Input() selected: ArticleOption[];
	@Input() article: Article;
	@Output() selection = new EventEmitter<ArticleOption>();
	@Input() preorderType: PreorderType;
	@Input() currency: string;

	utils = Utils;
	di = DisplayIdentifier;

	constructor() {}

	selectedOptionIndex() {
		const indexSelection = this.selected.findIndex(selectedOption => selectedOption.group === this.optionGroup._id);
		if (indexSelection < 0) {
			return indexSelection;
		}
		return this.optionGroup.articles.findIndex(article => article._id === this.selected[indexSelection].article._id);
	}

	onSelectionChange(article: Article) {
		const articleOption = new ArticleOption();
		articleOption.article = article;
		articleOption.group = this.optionGroup._id;
		articleOption.quantity = 1;
		this.selection.emit(articleOption);
	}

	price(option: Article): number {
		let price = Utils.getPrice(option, OrderType.PREORDER, this.preorderType);
		if (this.fullPrice()) {
			price += Utils.getPrice(this.article, OrderType.PREORDER, this.preorderType);
		}
		if (this.optionGroup.displayIdentifiers && this.optionGroup.displayIdentifiers?.includes(DisplayIdentifier.sides) === true) {
			const minOfGroup = Math.min(...this.optionGroup.articles.map(it => Utils.getPrice(it, OrderType.PREORDER, this.preorderType)));
			price -= minOfGroup;
			if (price == 0) {
				return minOfGroup;
			}
		}
		return price;
	}
	fullPrice(): boolean {
		return (
			this.optionGroup.displayIdentifiers &&
			this.optionGroup.displayIdentifiers.findIndex(di => di === DisplayIdentifier.fullPrice) >= 0
		);
	}
	notShowPlus(option: Article): boolean {
		const showPlusPrice =
			this.optionGroup.displayIdentifiers &&
			this.optionGroup.displayIdentifiers.findIndex(di => di === DisplayIdentifier.fullPrice) >= 0;
		let price = Utils.getPrice(option, OrderType.PREORDER, this.preorderType);
		if (this.fullPrice()) {
			price += Utils.getPrice(this.article, OrderType.PREORDER, this.preorderType);
		}
		if (this.optionGroup.displayIdentifiers && this.optionGroup.displayIdentifiers?.includes(DisplayIdentifier.sides) === true) {
			const minOfGroup = Math.min(...this.optionGroup.articles.map(it => Utils.getPrice(it, OrderType.PREORDER, this.preorderType)));
			price -= minOfGroup;
			if (price == 0) {
				return true;
			}
		}
		return showPlusPrice;
	}
}
