import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HomePage } from '../home/home.page';
import { MenuPage } from '../menu/menu.page';
import { RepositoryService } from '../../services/repository/repository.service';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-privacy',
	templateUrl: './privacy.page.html',
	styleUrls: ['privacy.page.scss'],
})
export class PrivacyPage {
	static url = 'privacy';
	constructor(private repository: RepositoryService, private router: Router, public modalCtrl: ModalController) {}

	static async navigate(router: Router) {
		await router.navigateByUrl(PrivacyPage.url);
	}

	static async show(modalCtrl: ModalController) {
		const modal = await modalCtrl.create({
			component: PrivacyPage,
			cssClass: 'privacy-modal',
		});
		await modal.present();
	}

	async goBack() {
		try {
			await this.modalCtrl.dismiss();
		} catch (e) {
			HomePage.navigate(this.router);
		}
	}
}
