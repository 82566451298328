import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RepositoryService } from '../../services/repository/repository.service';
import Venue from '../../../models/Venue';
import { MenuPage } from '../menu/menu.page';
import { PreorderType } from '../../../enums/PreorderType';
import { HomePage } from '../home/home.page';
import { MatSnackBar } from '@angular/material/snack-bar';
import Map = google.maps.Map;
import Marker = google.maps.Marker;
import LatLng = google.maps.LatLng;
import Utils from '../../../utils';
import { MapsUtils } from '../../../utils/maps-utils';

@Component({
	selector: 'app-venue',
	templateUrl: './venue.page.html',
	styleUrls: ['venue.page.scss'],
})
export class VenuePage implements OnInit {
	static url = 'store';
	private static venues: Venue[] = [];
	private static preorderType: PreorderType = null;

	@ViewChild('mapContainer', { static: false })
	gmap: ElementRef;
	map: Map;

	loading = true;
	venues: Venue[];
	preorderType: PreorderType;
	showMap = false;
	markers: Marker[] = [];
	mapOptions: google.maps.MapOptions = {
		zoom: 18,
		maxZoom: 18,
		disableDefaultUI: true,
		clickableIcons: false,
		styles: [
			{
				featureType: 'poi.business',
				stylers: [{ visibility: 'off' }],
			},
		],
	};
	selectedVenue: Venue = null;

	constructor(private router: Router, private repository: RepositoryService, private snackbarCtrl: MatSnackBar) {}

	static navigate(router: Router, venues: Venue[], preorderType: PreorderType) {
		VenuePage.venues = venues;
		VenuePage.preorderType = preorderType;
		router.navigateByUrl(VenuePage.url);
	}

	ionViewDidEnter() {
		this.loading = false;
		this.venues = VenuePage.venues;
		this.preorderType = VenuePage.preorderType;
		if (this.venues === undefined || this.venues === null || this.venues.length < 1 || !this.preorderType) {
			HomePage.navigate(this.router);
			return;
		}
		this.map = new Map(this.gmap.nativeElement, this.mapOptions);
		if (this.venues.length !== 0) {
			const latLng = Utils.getLatLng(this.venues[0]);
			this.map.setCenter(new LatLng(latLng.latitude - 0.00025, latLng.longitude));
			this.setupMap(this.venues[0], this.venues);
		} else {
			console.log('Venues with no coordinates');
		}
		this.loading = false;
	}

	ngOnInit() {}

	setupMap(selectedVenue: Venue, venues: Venue[]) {
		this.selectedVenue = selectedVenue;
		this.markers.forEach(mark => mark.setMap(null));
		//this.markers = MapsUtils.addVenuesToMap(this.markers, selectedVenue, venues, this.map, (venue, vens) => this.setupMap(venue, vens));
	}

	async selectVenue(venue: Venue, preorderType: PreorderType, attempt: number = 0, prevError: any = null) {
		if (attempt > 5) {
			this.loading = false;
			this.snackbarCtrl.open(prevError, null, {
				duration: 2000,
			});
			return;
		}
		this.loading = true;
		await this.repository.getVenue(venue._id);
		this.repository.createOrder(venue, null, preorderType);
		await MenuPage.navigate(this.router);
		this.loading = false;
	}
}
