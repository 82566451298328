import Venue from "src/models/Venue";

export const numberD = (x: any): number => {
	if (x === undefined) {
		return undefined;
	}
	if (x === null) {
		return null;
	}
	let candidate;
	if (x.$numberDecimal !== undefined) {
		candidate = +x.$numberDecimal;
	} else {
		candidate = +x;
	}
	if (isNaN(candidate)) {
		return null;
	}
	return candidate;
};
export const getLatLng = (
	venue: Venue
): {latitude: number; longitude: number} | null => {
	if (!venue || !venue.location || !venue.location.coordinates) {
		return null;
	}
	const latitude = venue.location.coordinates[1];
	const longitude = venue.location.coordinates[0];
	return {
		latitude,
		longitude
	};
};