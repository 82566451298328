import { Component, OnInit, ViewChild } from '@angular/core';
import { IonContent, ModalController, PickerController } from '@ionic/angular';
import { RepositoryService } from '../../services/repository/repository.service';
import Utils from '../../../utils';
import * as moment from 'moment';
import { PreorderType } from '../../../enums/PreorderType';
import validator from 'validator';
import { TosPage } from '../../pages/tos/tos.page';
import { PrivacyPage } from '../../pages/privacy/privacy.page';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
import { ValidationUtils } from '../../../utils/validation-utils';
import { OrderUtils } from '../../../utils/order-utils';
import { calculateGeoDistance, MapsUtils } from '../../../utils/maps-utils';
import RepositoryDirective from '../../pages/repository-directive';
import { TimeUtils } from 'src/utils/time-utils';
import Slot from 'src/models/Slot';

@Component({
	selector: 'app-checkout-modal',
	templateUrl: '../../../projects/aspasia/components/checkout-modal/checkout-modal.component.html',
	styleUrls: ['../../../projects/aspasia/components/checkout-modal/checkout-modal.component.scss'],
})
export class CheckoutModalComponent extends RepositoryDirective implements OnInit {
	utils = Utils;
	orderUtils = OrderUtils;
	loading = false;
	now: moment.Moment;
	pt = PreorderType;
	nameError: string;
	emailError: string;
	phoneError: string;
	orderAtError: string;
	streetError: string;
	streetNumberError: string;
	postalCodeError: string;
	cityError: string;
	hadPostalCode = false;
	isOpeningTimeSheet = false;
	telCountryOptions: any = {
		cssClass: 'tel-country-popover',
	};
	slots: Slot[] = [];
	showAsapForm = false;

	@ViewChild(IonContent, { static: false }) content;

	constructor(
		private modalCtrl: ModalController,
		protected repository: RepositoryService,
		private snackbarCtrl: MatSnackBar,
		private pickerCtrl: PickerController,
		private translate: TranslateService
	) {
		super(repository);
		if (this.address && this.address.postalCode && this.address.postalCode.length > 0) {
			this.hadPostalCode = true;
		}
		if (
			(this.address && !Utils.isEmpty(this.address.postalCode)) ||
			(OrderUtils.isPreorder(this.order) && !Utils.isEmpty(this.order.preorder.postalCode))
		) {
			this.hadPostalCode = true;
		}
	}

	ngOnInit() {
		super.ngOnInit();
		this.isAsapAvaibleNow();
	}

	onOrder() {
		super.onOrder();
		const postalCodeInOrder = OrderUtils.isPreorder(this.order) && !Utils.isEmpty(this.order.preorder.postalCode);
		if (OrderUtils.isPreorder(this.order)) {
			if (this.repository._previousPreorder) {
				if (!this.order.preorder.name) {
					this.order.preorder.name = this.repository._previousPreorder.name;
				}
				if (!this.order.preorder.email) {
					this.order.preorder.email = this.repository._previousPreorder.email;
				}
				if (!this.order.preorder.phone) {
					this.order.preorder.phone = this.repository._previousPreorder.phone;
				}
				if (!this.order.preorder.phoneCountry) {
					this.order.preorder.phoneCountry = this.repository._previousPreorder.phoneCountry;
				}
				if (!this.order.preorder.companyName) {
					this.order.preorder.companyName = this.repository._previousPreorder.companyName;
				}
				if (!this.order.preorder.floor) {
					this.order.preorder.floor = this.repository._previousPreorder.floor;
				}
			}
			if (!this.order.preorder.phoneCountry) {
				this.order.preorder.phoneCountry = Utils.phoneCountries[0];
			}
		}
		this.hadPostalCode = this.hadPostalCode || postalCodeInOrder;
	}

	onAddress() {
		super.onAddress();
		this.hadPostalCode = this.hadPostalCode || (this.address && !Utils.isEmpty(this.address.postalCode));
		this.reloadAddress();
	}

	onCustomer() {
		super.onCustomer();
		if (this.customer) {
			if (this.customer.postalCode && this.customer.postalCode.length > 0) {
				this.hadPostalCode = true;
			}
			// take fields from customer if not present in order/preorder
			this.order.preorder.email = this.order.preorder.email || this.customer.email;
			this.order.preorder.name = this.order.preorder.name || this.customer.name;
			this.order.preorder.phone = this.order.preorder.phone || this.customer.phone;
			this.order.preorder.phoneCountry = this.order.preorder.phoneCountry || this.customer.phoneCountry;
			this.order.preorder.companyName = this.order.preorder.companyName || this.customer.companyName;
			this.order.preorder.floor = this.order.preorder.floor || this.customer.floor;
			this.order.preorder.subcard = this.order.preorder.subcard || this.customer.subcard;
			if (this.order.preorder.type === PreorderType.DELIVERY) {
				this.order.preorder.street = this.order.preorder.street || this.customer.street;
				this.order.preorder.postalCode = this.order.preorder.postalCode || this.customer.postalCode;
				this.order.preorder.city = this.order.preorder.city || this.customer.city;
				if (!this.order.preorder.number && this.order.preorder.street === this.customer.street) {
					this.order.preorder.number = this.customer.number;
				}
			}
		}
	}

	requireVenueOrderPreorder(): boolean {
		if (!this.venue || !OrderUtils.isPreorder(this.order)) {
			this.snackbarCtrl.open(this.translate.instant('errors.data_error'), null, {
				duration: 2000,
			});
			return false;
		}
		return true;
	}

	requireName(): boolean {
		if (!this.order.preorder.name || this.order.preorder.name.length < 3) {
			this.nameError = this.translate.instant('errors.name');
			this.snackbarCtrl.open(this.nameError, null, {
				duration: 2000,
			});
			return false;
		}
		return true;
	}

	requireEmail(optional: boolean = false): boolean {
		if (Utils.isEmpty(this.order.preorder.email)) {
			return optional;
		}
		if (!validator.isEmail(this.order.preorder.email)) {
			this.emailError = this.translate.instant('errors.email');
			this.snackbarCtrl.open(this.emailError, null, {
				duration: 2000,
			});
			return false;
		}
		return true;
	}

	requirePhone(): boolean {
		if (!ValidationUtils.validatePhone(this.order.preorder.phoneCountry, this.order.preorder.phone)) {
			this.phoneError = this.translate.instant('errors.phone');
			this.snackbarCtrl.open(this.phoneError, null, {
				duration: 2000,
			});
			return false;
		}
		return true;
	}

	requireStreetAndNumber(): boolean {
		this.order.preorder.street = this.order.preorder.street.trim();
		this.order.preorder.number = this.order.preorder.number.trim();
		if (Utils.isEmpty(this.order.preorder.street) || Utils.isEmpty(this.order.preorder.number)) {
			this.streetError = this.translate.instant('address.street');
			this.snackbarCtrl.open(this.streetError, null, {
				duration: 2000,
			});
			return false;
		}
		return true;
	}

	async checkAddressChangeDelivery(): Promise<boolean> {
		// Address was changed in checkout modal check if venue delivers to this new address
		// new address needs to be checked if its in the delivery range
		this.order.preorder.street = this.order.preorder.street.trim();
		this.order.preorder.number = this.order.preorder.number.trim();
		this.order.preorder.postalCode = this.order.preorder.postalCode.trim();
		this.order.preorder.city = this.order.preorder.city.trim();

		if (
			OrderUtils.isDelivery(this.order) &&
			(this.order.preorder.street !== this.address.street ||
				this.order.preorder.number !== this.address.number ||
				this.order.preorder.postalCode !== this.address.postalCode ||
				this.order.preorder.city !== this.address.city)
		) {
			try {
				const pred =
					this.order.preorder.street +
					' ' +
					this.order.preorder.number +
					', ' +
					this.order.preorder.city +
					' ' +
					this.order.preorder.postalCode;

				const geocode = await MapsUtils.getPlace(pred);
				const address = MapsUtils.placeToAddress(geocode);
				const addressValidationResult = ValidationUtils.validateAddress(this.translate, address);
				if (addressValidationResult) {
					this.streetError = addressValidationResult;
					return false;
				}
				const delivers =
					this.venue.deliveryEnabled &&
					!this.venue.deliveryByRadius &&
					this.venue.deliveryPostalCodes.indexOf(address.postalCode);
				if (delivers < 0) {
					this.streetError = this.translate.instant('errors.street_delivery');
					this.snackbarCtrl.open(this.streetError, null, {
						duration: 2000,
					});
					return false;
				}
				this.repository.address.emit(address);
				this.order.preorder.street = this.address.street;
				this.order.preorder.number = this.address.number;
				this.order.preorder.postalCode = this.address.postalCode;
			} catch (e) {
				this.streetError = this.translate.instant('errors.street_not_found');
				this.snackbarCtrl.open(this.streetError, null, {
					duration: 2000,
				});
				return false;
			}
		}
		return true;
	}

	checkValidationAddress(error: string) {
		switch (error) {
			case 'address.street':
				this.streetError = error;
				return false;
			case 'address.number':
				this.streetNumberError = error;
				return false;
			case 'address.city':
				this.cityError = error;
				return false;
			case 'address.postalCode':
				this.postalCodeError = error;
				return false;
			default:
				return true;
		}
	}

	requirePostalCode(): boolean {
		if (Utils.isEmpty(this.order.preorder.postalCode)) {
			this.postalCodeError = this.translate.instant('errors.postal');
			this.snackbarCtrl.open(this.postalCodeError, null, {
				duration: 2000,
			});
			return false;
		}
		return true;
	}
	async isAsapAvaibleNow(): Promise<boolean> {
		this.slots = await Utils.getSlots(this.venue, this.order.preorder.type);
		const isOpenResturant = TimeUtils.doesHoursMatchNow(this.venue.openingHours);
		if (this.order.preorder.type === PreorderType.TAKE_AWAY) {
			if (isOpenResturant) {
				this.slots = [null, ...this.slots];
				this.showAsapForm = true;
				return true;
			} else {
				this.showAsapForm = false;
				return false;
			}
		} else {
			const mathcNow = TimeUtils.doesHoursMatchNow(this.venue.deliveryHours);
			if (mathcNow) {
				this.slots = [null, ...this.slots];
				this.showAsapForm = true;
				return true;
			} else {
				this.showAsapForm = false;
				return false;
			}
		}
	}
	requireOrderAt(): boolean {
		if (!this.showAsapForm && !this.order.orderAt) {
			this.orderAtError = this.translate.instant('errors.order_at');
			this.snackbarCtrl.open(this.orderAtError, null, {
				duration: 2000,
			});
			return false;
		}
		if ((OrderUtils.isDelivery(this.order) || OrderUtils.isTakeAway(this.order)) && !this.order.orderAt) {
			this.order.asap = true;
		}
		if (!this.order.orderAt || moment(this.order.orderAt).isBefore(moment())) {
			if (!OrderUtils.isTakeAway(this.order) && !OrderUtils.isDelivery(this.order) && !this.order.asap) {
				this.orderAtError = this.translate.instant('errors.order_at');
				this.order.orderAt = null;
				this.snackbarCtrl.open(this.orderAtError, null, {
					duration: 2000,
				});
				return false;
			}
		}
		return true;
	}

	resetErrors() {
		this.nameError = null;
		this.emailError = null;
		this.phoneError = null;
		this.orderAtError = null;
		this.streetError = null;
		this.streetNumberError = null;
		this.postalCodeError = null;
		this.cityError = null;
	}

	async validate(): Promise<boolean> {
		this.resetErrors();
		return (
			this.requireVenueOrderPreorder() &&
			this.requireName() &&
			this.requireEmail(true) &&
			this.requirePhone() &&
			this.requireStreetAndNumber() &&
			(await this.checkAddressChangeDelivery()) &&
			this.requirePostalCode() &&
			this.requireOrderAt()
		);
	}

	async openTimeSheet() {
		if (!this.venue) {
			this.snackbarCtrl.open(this.translate.instant('errors.venue'), null, {
				duration: 2000,
			});
			return;
		}
		if (this.isOpeningTimeSheet) {
			return;
		}
		this.isOpeningTimeSheet = true;
		this.loading = true;
		try {
			const options = {
				columns: [
					{
						name: 'slot',
						class: 'time-picker',
						options: this.slots.map(slot => {
							return {
								text: this.slotToText(slot ? moment(slot.time) : null),
								value: slot,
							};
						}),
					},
				],
				buttons: [
					{
						text: this.translate.instant('checkout_modal.cancel'),
						role: 'cancel',
					},
					{
						text: this.translate.instant('checkout_modal.confirm'),
						handler: value => {
							if (!value?.slot?.value) {
								this.order.asap = true;
								this.order.orderAt = null;
								return;
							}
							const conflictingArticles = OrderUtils.slotConflictingArticlesInOrder(
								moment(value.slot.value.time),
								this.order
							);
							if (conflictingArticles.length > 0) {
								this.snackbarCtrl.open(
									this.translate.instant('checkout_modal.products_unavailable') +
										conflictingArticles
											.map(conflictingArticle => conflictingArticle.article.name.de)
											.reduce((previousValue, currentValue) => previousValue + ' ' + currentValue),
									null,
									{
										duration: 3000,
									}
								);
								return;
							}
							this.order.orderAt = moment(value.slot.value.time).local();
						},
					},
				],
			};
			const picker = await this.pickerCtrl.create(options);

			await picker.present();
		} catch (e) {
			this.snackbarCtrl.open(this.translate.instant('checkout_modal.time_error'), null, {
				duration: 2000,
			});
			console.error(e);
		}
		this.isOpeningTimeSheet = false;
		this.loading = false;
	}

	async close() {
		if (!(await this.validate())) {
			await Utils.sleep(100);
			const firstErrorElement: HTMLElement = [...this.content.el.children].find(el => {
				return el.className.split(' ').findIndex(clazz => clazz === 'input-error') >= 0;
			});
			if (firstErrorElement !== undefined) {
				await this.content.scrollToPoint(0, firstErrorElement.offsetTop, 300);
			}
			return;
		}
		const data = {
			preorder: this.order.preorder,
			orderAt: this.order.orderAt,
			asap: this.order.asap,
		};
		this.repository.previousPreorder.emit(this.order.preorder);
		this.snackbarCtrl.dismiss();
		await this.modalCtrl.dismiss(data);
	}

	async dismiss() {
		await this.modalCtrl.dismiss();
	}

	reloadAddress() {
		if (this.order && this.order.preorder && this.address && this.order.preorder.type === PreorderType.DELIVERY) {
			this.order.preorder.city = this.address.city;
			this.order.preorder.street = this.address.street;
			if (this.address.postalCode && this.address.postalCode.length > 0) {
				this.order.preorder.postalCode = this.address.postalCode;
			}
			this.order.preorder.country = this.address.country;
			this.order.preorder.number = this.address.number;
		}
	}

	async openTos() {
		await TosPage.show(this.modalCtrl);
	}

	async openPrivacy() {
		await PrivacyPage.show(this.modalCtrl);
	}

	slotToText(slot: Moment): string {
		if (!slot) {
			return this.translate.instant('checkout_modal.asap');
		}
		const now = moment();
		const sameDay = slot.dayOfYear() === now.dayOfYear() && slot.year() === now.year();
		if (sameDay) {
			return slot.format('HH:mm');
		} else {
			return slot.format('DD.MM. HH:mm');
		}
	}

	getPlaceholder(key: string): string {
		return `${this.translate.instant(key)} ${this.translate.instant('checkout_modal.type')}`;
	}
	showTextIsDeliveryNotAvaible() {
		if (this.showAsapForm) {
			return 'schnellstmöglich';
		} else {
			return 'Datum auswählen';
		}
	}
}
